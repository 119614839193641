// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-area-page-js": () => import("/opt/build/repo/src/templates/area-page.js" /* webpackChunkName: "component---src-templates-area-page-js" */),
  "component---src-pages-horarios-tarifas-js": () => import("/opt/build/repo/src/pages/horarios-tarifas.js" /* webpackChunkName: "component---src-pages-horarios-tarifas-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */)
}

